import { VestaboardContextProvider } from "@vestaboard/installables/lib/hooks";
import { Settings } from "./pages/Settings";
import "./App.css";
import { Theme } from "@vestaboard/installables/lib/components/Theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Help } from "./pages/Help";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Settings />,
  },
  {
    path: "help",
    element: <Help />,
  },
]);

const App = () => {
  return (
    <VestaboardContextProvider>
      <Theme>
        <RouterProvider router={router} />
      </Theme>
    </VestaboardContextProvider>
  );
};

export default App;
