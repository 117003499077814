import {
  Body,
  SubTitle,
  Title,
} from "@vestaboard/installables/lib/components/Typography";
import { Button, Divider, Spacer } from "@vestaboard/installables";
import { Box } from "@mui/material";

const FAQ = (props: { question: string; answers: Array<any> }) => {
  return (
    <>
      <SubTitle>{props.question}</SubTitle>
      {props.answers.map((answer) => (
        <>
          <Spacer size={"medium"} />
          <Body>{answer}</Body>
          <Spacer size={"medium"} />
          <Divider />
        </>
      ))}
      <Spacer size={"large"} />
    </>
  );
};

export const Help = () => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          padding: 16,
          paddingTop: 32,
        }}
      >
        <Box
          style={{
            textAlign: "left",
            width: "100%",
            maxWidth: 600,
          }}
        >
          <Title>Trouble Shooting</Title>
          <Spacer size={"large"} />
          <FAQ
            question="Not seeing your Vestaboard in the dropdown?"
            answers={[
              <>
                {"Be sure you have logged into"}{" "}
                <a
                  style={{ color: "white" }}
                  href={"https://web.vestaboard.com"}
                >
                  Vestaboard
                </a>
                {
                  ", navigated to Plus, added Zapier, and clicked Authorize Zapier. Make sure you see the board in the top right."
                }
                ,
              </>,
              "If you have already done this, click Manage Zapier from the Plus settings screen. This action will update your boards in the Zapier dropdown.",
            ]}
          />
          <FAQ
            question="Are you seeing boards for a previous account?"
            answers={[
              "Make sure You have logged out of your Vestaboard account in your browser. Log back into Vestaboard. Go to to Zapier and connect another your account to your Zap again.",
            ]}
          />
          <FAQ
            question="Have you gotten an email that your Zap has been throttled or rate-limited?"
            answers={[
              "Be sure that your Zap and other Vestaboard+ channels are not firing within 15 seconds of eachother and that you are not firing too many tasks to a Vestaboard from Zapier. We can handle no more than 1 message every 15 seconds per Vestaboard.",
            ]}
          />
          <Spacer size="medium" />

          <Body>Need further Zapier assistance?</Body>
          <Spacer size="medium" />
          <Button
            onClick={() =>
              window.open(
                "mailto:plus-developers@vestaboard.com?subject=Zapier"
              )
            }
            buttonType={"white"}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </>
  );
};
