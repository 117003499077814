import { useCallback } from 'react'

interface IUseUpdateDetails {
  subscriptionId: string
  title?: string
  iconUrl?: string
}

/**
 * _useUpdateDetails_
 *
 * Posts updates to Installable details i.e.(title and icon url) to the React Native app's WebView component
 * iconUrl via the window `postMessage()` method.
 *
 * @example
 *
 * ```tsx
 *
 * import { useUpdateDetails } from '@vestaboard/installable-kit/hooks'
 *
 * const { updateDetails } = useUpdateDetails()
 *
 * updateDetails('New Title')
 *
 * ```
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */
export const useUpdateDetails = () => {
  const updateTitle = useCallback(
    ({ title, iconUrl, subscriptionId }: IUseUpdateDetails) => {
      try {
        const anyWindow = window as any
        const data = JSON.stringify({
          topic: 'details',
          subscriptionId,
          title,
          iconUrl,
        })

        // For React Native WebView, post to the WebView
        if (anyWindow?.ReactNativeWebView) {
          anyWindow?.ReactNativeWebView.postMessage(data)

          // For IFrames, post to the parent window
        } else if (window?.parent) {
          window.parent.postMessage(data, '*')
        }
      } catch (error) {
        console.error(error)
      }

      return null
    },
    [],
  )

  return {
    updateTitle,
  }
}
