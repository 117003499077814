import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../pages/Settings";

export const getQuietHoursData = async (boardId: string) => {
  const resp = await axios.get(
    `/api/board/${boardId}/quiethours`,
    {}
  );
  return resp.data as {
    formattedQuietHours: string;
    isQuietHours: boolean;
  };
};

export const getSubscriptionInfo = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  return response.data as any;
};

export const useIsQuietHours = ({
  boardId,
}: {
  boardId?: string | null | undefined;
}) => {
  const [isQuietHours, setIsQuietHours] = useState(false);
  const [formattedQuietHours, setFormattedQuietHours] = useState("");

  const checkQuietHours = useCallback(async () => {
    if (boardId) {
      const data = await getQuietHoursData(boardId);
      setIsQuietHours(data.isQuietHours);
      setFormattedQuietHours(data.formattedQuietHours);
    }
  }, [boardId]);

  useEffect(() => {
    // check on load
    checkQuietHours();
  }, [checkQuietHours, boardId]);

  useEffect(() => {
    // check every minute
    const interval = setInterval(() => {
      checkQuietHours();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [checkQuietHours]);

  return { isQuietHours, formattedQuietHours };
};
